<template>
  <div class="wrap">
    <div class="header">
      <headerTop></headerTop>
    </div>
    <!-- 招贤纳士 -->
    <div class="waiting_you" ref="wait">
      <div class="top">
        <h3 class="title">招贤纳士，我们在等你</h3>
        <i class="line"></i><span>多个岗位，欢迎你的投递</span>
      </div>
      <div class="banner_box">
        <ul class="left">
          <li :class="item.state === true ? 'banner_active' : ''" v-for="(item, index) in bannerData" :key="index" @mouseenter="activeChange(index)">{{ item.name }}</li>
        </ul>
        <div class="right">
          <div class="title_box">
            <span class="titOne">{{ showBanner.name }}</span>
            <ul>
              <li v-for="(item, index) in showBanner.titleData" :key="index">{{ item }}<span>|</span></li>
            </ul>
          </div>
          <div class="duty">
            <span>岗位职责：</span>
            <p v-html="showBanner.duty"></p>
          </div>
          <div class="requirement">
            <span>任职要求：</span>
            <p v-html="showBanner.requirement"></p>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="left">
          <h3 class="tit">快捷投递简历通道</h3>
          <div class="email"><span>扫码就可直接联系到我们Hr小姐姐哦～</span><span>也可直接投递简历至Hr邮箱</span><span>邮箱：hr@smart-insight.com.cn</span></div>
        </div>
        <div class="right">
          <div class="QR_item"><img src="../assets/img/contactUs/QR_zl.png" alt="" /><span>智联招聘投递通道</span></div>
          <div class="QR_item"><img src="../assets/img/contactUs/QR_boss.png" alt="" /><span>BOSS直聘投递通道</span></div>
          <div class="QR_item"><img src="../assets/img/contactUs/QR_lg.png" alt="" /><span>拉勾投递通道</span></div>
        </div>
      </div>
    </div>
    <!-- 公司地址 -->
    <div class="address_box" ref="address">
      <h3 class="title">公司地址</h3>
      <div class="add">
        <p class="tit">总部<i>|</i>北京</p>
        <span>北京市海淀区海淀北二街8号中关村soho大厦A区801</span>
        <span class="span">联系电话&nbsp;&nbsp;&nbsp;&nbsp; 010-62533951（总部）</span>
      </div>
      <div class="add">
        <p class="tit">分部<i>|</i>天津</p>
        <span>天津市津南区津沽大街295号阿里云创新中心（天津津南）基地3层C16</span>
      </div>
      <div class="add">
        <p class="tit">分部<i>|</i>盐城</p>
        <span>江苏省盐城市盐都区盐龙街道秦川路与盐渎路交叉口（总部研发区E4号楼）</span>
      </div>
    </div>
    <pageFooter @goAnchor="goAnchor"></pageFooter>
    <div class="loading">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  </div>
</template>

<script>
import headerTop from '@/components/header.vue'
import pageFooter from '@/components/footer.vue'
export default {
  name: 'ContactUs',
  components: {
    headerTop,
    pageFooter
  },
  data() {
    return {
      bannerData: [
        {
          name: '软件测试工程师',
          state: true,
          titleData: ['10-20K', '五险一金', '带薪年假', '双休', '年终奖'],
          duty: `1、根据产品功能需求设计测试方案，制定并执行测试计划；</br>
                2、设计测试脚本和要点，完成产品的功能测试工作；</br>
                3、详细准确填写BUG信息，并尽可能地提出修改建议；</br>
                4、跟踪BUG，并确认问题得以妥善解决；</br>
                5、总结和分析测试结果，提出产品改进建议和优化研发流程建议；</br>
                6、完成项目的集成测试和系统测试、对项目软件的功能和性能及其它方面的测试负责；</br>
                7、对其他部门提出的技术问题予以支持。</br>`,
          requirement: `1、熟练掌握测黑盒测试及用例设计方法，有性能测试经验优先；</br>
                    2、熟悉自动化测试，有实际项目的性能测试经验或自动化测试经验者优先；</br>
                    3、熟悉loadrunner、QTP等工具者，具备相关测试环境搭建经验者优先；</br>
                    4、熟悉Linux命令；</br>
                    5、熟悉Python等，有初步编程经验优先；</br>
                    6、熟练使用 SQL Server、Oracle、MySQL其中一种数据库工具，能够熟练编写sq语句；</br>
                    7、逻辑思维能力好，有较强的业务学习能力，具有良好的团队协作精神；</br>
                    8、有很强沟通能力，具有强烈的工作责任心。</br>`
        },
        {
          name: 'Python开发工程师',
          state: false,
          titleData: ['12-24K', '五险一金', '带薪年假', '双休', '年终奖'],
          duty: `1、负责公司SaaS业务产品开发计划制定,及开发全流程把控；</br>
                  2、参与公司平台架构分析与设计;</br>
                  3、带领初级程序员解决技术难题；</br>
                  4、日常技术分享，设计文档、技术文档的编写等。</br>`,
          requirement: `1、本科及以上学历，计算机相关专业，2年以上研发工作经验;</br>
                        2、了解Http协议,擅长RESTfulAPI的设计与开发;</br>
                        3、熟悉Python语言，2年以上中、大型系统架构经验，对Python3异步编程熟悉者优佳;</br>
                        4、熟悉Django/Flask/Sanic/Tornado等Web框架一种或者多种;</br>
                        5、熟练使用git版本管理工具；</br>
                        6、良好的沟通能力和协调能力，有团队合作精神;</br>
                        7、中大型以上研发团队管理经验，项目计划和推进能力;</br>
                        8、熟悉Linux环境使用，擅长编写shell脚本优先;</br>
                        9、有docker,k8s,jenkins,git-flow等部署和使用经验者优先；</br>
                        10、熟悉使用ORM，并对其底层实现原理有清晰认识，阅读过源码优佳。</br>`
        },
        {
          name: '运维开发工程师',
          state: false,
          titleData: ['15-20K', '五险一金', '带薪年假', '双休', '年终奖'],
          duty: `1、负责公司的DevOps能力建设，推动公司自动化运维、部署，提升整体维护效率；</br>
                  2、配合研发完成版本的配置管理、推进产品版本标准化运维工作；</br>
                  3、保障系统层面的稳定运行，有能力处理突发事件、对疑难问题进行跟踪并最终解决；</br>
                  4、总结和抽提日常运维工作，形成运维自动化平台产品设计方案；</br>
                  5、负责制定数据迁移方案及实现；</br>
                  6、支援Python开发。</br>`,
          requirement: `1、统招本科毕业,三年及以上运维开发工作经验；</br>
                        2、熟悉CI/CD流程和工具，并具备落地经验（jenkins）；</br>
                        3、精通Shell、Python、Perl等脚本语言；</br>
                        4、熟悉CentOS、Ubuntu等常用操作系统原理以及服务器硬件，TCP/IP以及常用的网络协议；熟悉常见服务；</br>
                        5、熟悉自动化运维管理工具（puppet/saltstack/ansible），有监控（zabbix,Prometheus）经验；</br>
                        6、精通Nginx、Python、MySQL、Redis、MongoDB、ElasticSearch、TiDB、ClickHouse等服务运维与调优；</br>
                        7、熟悉虚拟化平台(Vmware、KVM、Docker、K8S)，有能力编排docker file；</br>
                        8、有日志处理及分析相关经验，熟悉相关的组件，如：kafka，elk，storm，HDFS等；</br>
                        9、精通MySQL数据库相关维护知识，具备数据库安全、优化、主从读写分离、MHA、MGR、扩容、灾备等技术，有数据安全、灾难恢复等实战经验；</br>
                        10、熟悉运维自动化平台，参与过运维体系建设者优先。</br>`
        },
        {
          name: 'Java开发工程师',
          state: false,
          titleData: ['12-20K', '五险一金', '带薪年假', '双休', '年终奖'],
          duty: `1、负责公司业务系统的设计、开发以及第三方接口对接工作；</br>
                  2、分析业务需求，对业务进行划分、能独立进行产品新功能的需求开发；</br>
                  3、技术预研和技术难点公关，保证系统的可靠性，扩展性；</br>
                  4、协助并完成其他各类技术开发任务。</br>`,
          requirement: `1、计算机相关专业，扎实的java基础，3年以上java开发经验，有保险、金融行业经历；</br>
                        2、熟悉常用互联网技术组件及中间件（Tomcat、Weblogic、 Nginx、Redis、RabbitMq等）；</br>
                        3、熟练使用mysql数据库、oracle数据库、具备一定的调优技能，以及数据库设计能力；</br>
                        4、熟悉并掌握Spring Boot/Spring Cloud/Spring MVC/SSM架构，熟练使用Java进行编程，熟练配置Eclipse、IDEA、Maven、git等开发环境；</br>
                        5、熟练使用Linux常用命令，能独立查询并解决测试以及生产问题；</br>
                        6、具有良好的沟通能力、团队合作精神和职业稳定性，逻辑性强，工作积极主动、服从安排，能吃苦，能承受一定工作压力。</br>`
        },
        {
          name: 'NLP算法工程师',
          state: false,
          titleData: ['20-35K', '五险一金', '带薪年假', '双休', '年终奖'],
          duty: `1、负责NLP在业务需求的研发和开发；</br>
                例如中文分词，文本结构化，文本分类，NLP表示学习，NER,词性标注，关系抽取，知识图谱等等；</br>
                2、负责NLP处理前沿问题的研究和落地（主要是医疗领域），结合实际应用场景，提供技术解决方案。</br>`,
          requirement: `1、计算机及相关专业本科及以上学历（1-3年NLP相关工作经验）；</br>
                        2、精通自然语言处理相关领域知识和技能，且有扎实的算法基础，在NLP领域有一个或者多个深入的项目实践；</br>
                        3、熟悉常见的深度学习或者机器学习框架；</br>
                        4、熟悉C++/Python ,熟悉linux环境下算法开发和部署；</br>
                        5、有医疗领域相关NLP经验者优先。</br>`
        }
      ],
      showBanner: {}
    }
  },
  created() {
    this.showBanner = this.bannerData[0]
    window.scroll(0, 0)
  },
  mounted() {
    if (this.$route.params.anchor) {
      this.$refs[this.$route.params.anchor].scrollIntoView(true)
    }
  },
  methods: {
    // tab切换
    activeChange(i) {
      // console.log(i)
      this.bannerData.forEach((item) => {
        item.state = false
      })
      this.bannerData[i].state = true
      this.showBanner = this.bannerData[i]
    },
    // 切换锚点
    goAnchor(val) {
      this.$refs[val].scrollIntoView(true)
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #fff;
  z-index: 999;
}
.wrap {
  padding-top: 60px;
}
.waiting_you {
  overflow: hidden;
  .top {
    text-align: center;
    height: 557px;
    background: url('../assets/img/contactUs/contactUs_top_bg.png') no-repeat;
    background-size: 100% 557px;
    overflow: hidden;
    .title {
      margin-top: 142px;
      height: 40px;
      font-size: 28px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #010c29;
      line-height: 40px;
    }
    i {
      display: block;
      margin: 60px auto 0;
      width: 28px;
      height: 1px;
      background-color: #788398;
    }
    span {
      display: block;
      margin-top: 17px;
      height: 42px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #788398;
      line-height: 42px;
    }
    // 头部渐入
    @-webkit-keyframes slide-in-top {
      0% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
      }
    }
    @keyframes slide-in-top {
      0% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
  .banner_box {
    margin-top: -131px;
    padding: 0 130px;
    height: 774px;
    .left {
      float: left;
      width: 400px;
      li {
        position: relative;
        padding-left: 52px;
        margin-bottom: 20px;
        width: 400px;
        height: 120px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #212632;
        line-height: 120px;
        background: url('../assets/img/contactUs/banner_unchecked.png') no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        box-sizing: border-box;
        transition: margin-left 0.3s;
        &::after {
          position: absolute;
          top: 48px;
          right: 46px;
          content: '';
          display: block;
          width: 24px;
          height: 24px;
          background: url('../assets/icon/contactUs/banner_arrow_unchecked.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      .banner_active {
        margin-left: 20px;
        color: #ffffff;
        background: url('../assets/img/contactUs/banner_checked.png') no-repeat;
        background-size: 100% 100%;
        &::after {
          background-image: url('../assets/icon/contactUs/banner_arrow_checked.png');
        }
      }
    }
    .right {
      float: right;
      width: 660px;
      .title_box {
        margin-bottom: 28px;
        height: 132px;
        border-bottom: 1px solid #edf0f5;
        overflow: hidden;
        box-sizing: border-box;
        .titOne {
          display: block;
          margin-top: 24px;
          height: 28px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #212632;
          line-height: 28px;
        }
        ul {
          margin-top: 28px;
          li {
            float: left;
            height: 28px;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #212632;
            line-height: 28px;
            span {
              margin: 0 35px;
              display: inline-block;
            }
            &:last-child {
              span {
                display: none;
              }
            }
          }
        }
      }
      .duty,
      .requirement {
        overflow: hidden;
        span {
          display: block;
          margin: 12px 0 0;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #212632;
          line-height: 20px;
        }
        p {
          margin: 20px 0;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #788398;
          line-height: 28px;
        }
      }
      .requirement {
        p {
          margin-bottom: 0;
        }
      }
    }
  }
  .bottom {
    position: relative;
    padding-left: 98px;
    height: 520px;
    .left {
      float: left;
      width: 350px;
      background: url('../assets/img/contactUs/comma_bg.png') no-repeat;
      background-size: 300px 300px;
      .tit {
        margin-top: 282px;
        margin-left: 84px;
        // width: 160px;
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #212632;
        line-height: 28px;
      }
      .email {
        margin-left: 84px;
        margin-top: 32px;
        span {
          display: block;
          margin-bottom: 24px;
          // width: 240px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #788398;
          line-height: 20px;
        }
      }
    }
    .right {
      position: absolute;
      bottom: 0;
      right: 0;
      padding-top: 92px;
      width: 876px;
      height: 370px;
      background: url('../assets/img/contactUs/QR_bg.png') no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      .QR_item {
        margin-left: 84px;
        float: left;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 160px;
        img {
          width: 160px;
          height: 160px;
        }
        span {
          margin-top: 24px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
        }
        &:nth-child(1) {
          margin-left: 98px;
        }
      }
    }
  }
}
// 公司地址
.address_box {
  height: 752px;
  text-align: center;
  background: url('../assets/img/contactUs/address_bg.png') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  .title {
    margin-top: 136px;
    padding-bottom: 40px;
    height: 40px;
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #010c29;
    line-height: 40px;
    text-align: center;
    overflow: hidden;
  }
  .add {
    margin-top: 60px;
    overflow: hidden;
    .tit {
      margin-bottom: 24px;
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #212632;
      line-height: 28px;
      i {
        display: inline-block;
        margin: 0 30px;
        font-style: normal;
      }
    }
    span {
      display: block;
      margin-bottom: 16px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #212632;
      line-height: 20px;
    }
    .span {
      margin-bottom: 0;
    }
  }
}
</style>
